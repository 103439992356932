import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { mediaMaxPx } from 'styles';
import imageDecoration from './assets/image_decoration.png';

export const ScrollWrapper = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  align-items: center;
`;

export const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 1400px;
  opacity: ${props => (props.isActive ? 1 : 0)};
  pointer-events: ${props => (props.isActive ? 'all' : 'none')};
  transition: opacity 0.3s;
  position: relative;
  padding: 64px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  ${mediaMaxPx(
    800,
    css`
      height: fit-content;
      margin-bottom: 40vh;
      align-items: flex-start;
    `,
  )}

  ${mediaMaxPx(
    680,
    css`
      justify-content: center;
      padding: 0;
    `,
  )}
`;

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  max-height: 100%;
  ${mediaMaxPx(
    680,
    css`
      flex-direction: column-reverse;
      align-items: center;
    `,
  )}
`;

export const ImageContainer = styled.div`
  width: 60%;
  height: fit-content;
  max-height: 100%;
  flex: 1;
  position: relative;
  img {
    display: block;
    width: 100%;
    max-height: 100%;
  }

  &::after {
    content: url(${imageDecoration});
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40.78px;
    height: 30.91px;
    ${mediaMaxPx(
      680,
      css`
        left: 0;
      `,
      8,
    )}
  }

  ${mediaMaxPx(
    680,
    css`
      width: 100%;
    `,
  )}
`;

export const TextContainer = styled.div`
  width: 40%;
  padding-left: 50px;
  display: flex;
  align-items: flex-start;
  hyphens: none;

  p {
    margin: 0;
  }

  a {
    color: black;
    text-decoration: none;
  }

  p a {
    color: ${colors.primary};
    text-decoration: underline;
  }
  ${mediaMaxPx(
    1000,
    css`
      p {
        font-size: 48px;
        line-height: 56px;
      }
    `,
  )}

  ${mediaMaxPx(
    680,
    css`
      padding: 0;
      width: 100%;
      margin-bottom: 16px;
    `,
  )}
`;

export const Link = styled.span`
  color: ${colors.text.accent};
  position: relative;
  &::after {
    content: '';
    height: 8px;
    width: 100%;
    position: absolute;
    background-color: ${colors.underscore};
    bottom: 0;
    left: 0;
  }
`;
